.tiptap ul, .tiptap ol {
    list-style-type: none; /* Remove default list style */
    padding: 0;
    margin: 0;
  }
  
  .tiptap li {
    display: flex; /* Use flexbox to align bullet and text */
    align-items: center; /* Vertically center the bullet and text */
    margin-bottom: 1em; /* Add some space between list items */
  }
  
  .tiptap li::before {
    content: none; /* Remove default marker if still present */
  }
  
  .tiptap li p {
    display: inline; /* Display p elements inline to keep them on the same line as the bullet */
    margin: 0;
  }
  
  .ProseMirror-widget {
    display: inline-block;
    vertical-align: middle; /* Aligns the widget in the middle of the text line */
    margin-right: 8px; /* Adds some space between the marker and the text */
  }
  
  